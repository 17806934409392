.dropdown {
  position: absolute;
  right: 1%;
  top: 2%;
  width: 110px;
  filter: url(#goo);
  cursor: pointer;

  &__face,
  &__items {
    background-color: #eff7fd;
    padding: 15px 20px;
    border-radius: 15px;
  }

  &__face {
    display: block;
    position: relative;
    cursor: pointer;
  }

  &__items {
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 65%;
    list-style: none;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);

    &::before {
      content: '';
      background-color: #eff7fd;
      position: absolute;
      bottom: 100%;
      right: 20%;
      height: 40px;
      width: 20px;
    }
  }

  &__arrow {
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    position: absolute;
    top: 50%;
    right: 30px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: right;
  }

  input {
    display: none;

    &:checked ~ .dropdown__items {
      top: calc(100% + 25px);
      visibility: visible;
      opacity: 1;
    }
  }
}

.selector-svg {
  display: none;
}
