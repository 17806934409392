@import "./components/main.scss";
iframe {display: none}

.App {
  header.header-desk {
    height: 90px;
    width: 100%;
    background-image: linear-gradient(to right, #0e3d60, #49bdbc);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    z-index: 99999;

    @include device-rule(mobile) {
      background-image: none;
      background-color: red;
      display: none;
    }

    .header-content-container {
      width: 1024px;
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      .logo {
        height: 40px;
        margin-bottom: 9px;
      }

      nav {
        display: flex;
        justify-content: space-between;
        font-family: "Baloo 2";
        font-weight: 400;
        height: 100%;

        .nav-item {
          padding: 0px 60px;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          white-space: nowrap;
          border-bottom: 5px solid transparent;

          &:last-child {
            padding-right: 0;
          }

          &.selected {
            border-bottom: 5px solid $squash;
          }
        }
      }
    }
  }

  header.header-mobile {
    display: none;
    height: 60px;
    background-image: linear-gradient(to right, #0e3d60, #49bdbc);
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    position: fixed;
    z-index: 999999;
    width: calc(100% - 50px);

    @include device-rule(mobile) {
      display: flex;
    }

    img {
      height: 28px;
    }
  }

  footer {
    background-color: $light-navy;
    padding: 35px 45px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 5px;

    @include device-rule(mobile) {
      padding: 25px;
    }

    .footer-content {
      width: 1024px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include device-rule(mobile) {
        flex-direction: column;
        align-items: center;
      }

      .footer-contact {
        display: flex;
        flex-direction: column;

        @include device-rule(mobile) {
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          > img {
            width: 90px;
          }

          .tel {
            margin-top: 0 !important;
          }
        }

        .tel {
          display: flex;
          align-items: center;
          margin-top: 15px;

          img {
            margin-right: 5px;
          }

          .num {
            font-family: "Open Sans";
            font-size: 0.875em;
            color: white;
          }
        }
      }

      .pages,
      .legal-links {
        font-family: "Open Sans";
        font-size: 0.875em;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        @include device-rule(mobile) {
          margin-top: 0;
          text-align: center;
        }

        a {
          color: white;
          text-decoration: none;
          margin-bottom: 20px;
          transition: all 0.3s;
          cursor: pointer;

          &:hover {
            color: $squash;
          }
        }
      }

      .pages {
        @include device-rule(mobile) {
          margin-top: 40px;
        }
      }

      .follow-us {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .title {
          font-family: "Open Sans";
          font-size: 0.875em;
          color: white;

          @include device-rule(mobile) {
            display: none;
          }
        }

        .social-links {
          display: flex;
          justify-content: space-around;
          margin-top: 5px;

          @include device-rule(mobile) {
            column-gap: 30px;
          }

          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            background-color: white;
            border-radius: 50%;
            border: 2px solid transparent;
            cursor: pointer;
            transition: all 0.3s;

            @include device-rule(mobile) {
              height: 35px;
              width: 35px;
            }

            &:hover {
              border: 2px solid $squash;
            }
          }
        }
      }
      @include device-rule(mobile) {
        .conformity-label {
          display: none;
        }
      }
    }
  }
}
