// ICONS
$icon-size: 5em !default;
$icon-animations: true !default;
$icon-margin: 2.5em auto 0.6em !default;
$icon-font-family: inherit !default;
$icon-font-size: 3.75em !default;
$icon-border-color: #000 !default;
$icon-zoom: null !default;
$success: #a5dc86 !default;
$success-border: rgba($success, 0.3) !default;
$error: #f27474 !default;
$warning: #f8bb86 !default;
$info: #3fc3ee !default;
$question: #87adbd !default;

// Toast animations
// Animations
@keyframes toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }

  33% {
    transform: translateY(0) rotateZ(-2deg);
  }

  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }

  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}

@keyframes toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}

@keyframes toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }

  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }

  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }

  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }

  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}

@keyframes toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }

  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }

  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }

  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
// -------------------------------

// Appearance animation
@keyframes show {
  0% {
    transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

// Disppearance animation
@keyframes hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

// Success icon animations
@keyframes animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }

  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }

  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }

  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }

  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}

@keyframes animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }

  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }

  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }

  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}

@keyframes rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

// Error icon animations
@keyframes animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }

  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }

  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }

  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Question mark animation
@keyframes animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }

  100% {
    transform: rotateY(0);
  }
}

// Info and Warning mark animation
@keyframes animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }

  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }

  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }

  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

// ----------------------------------

@function strip-units($number) {
  @return calc($number / ($number * 0 + 1em));
}
$icon-zoom: calc(strip-units($icon-size) / 5);

div:where(.icon) {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: $icon-size;
  height: $icon-size;
  // margin: $icon-margin;
  margin-bottom: 3rem;
  zoom: $icon-zoom;
  border: #{0.25em * $icon-zoom} solid transparent;
  border-radius: 50%;
  border-color: $icon-border-color;
  font-family: $icon-font-family;
  line-height: $icon-size;
  cursor: default;
  user-select: none;
  font-size: 1.5rem;

  .icon-content {
    display: flex;
    align-items: center;
    font-size: $icon-font-size;
  }

  &.error {
    border-color: $error;
    color: $error;

    .x-mark {
      position: relative;
      flex-grow: 1;

      @if $icon-zoom != 1 {
        zoom: $icon-zoom;
      }
    }

    [class^='x-mark-line'] {
      display: block;
      position: absolute;
      top: 2.3125em;
      width: 2.9375em;
      height: 0.3125em;
      border-radius: 0.125em;
      background-color: $error;

      &[class$='left'] {
        left: 1.0625em;
        transform: rotate(45deg);
      }

      &[class$='right'] {
        right: 1em;
        transform: rotate(-45deg);
      }
    }

    // Error icon animation
    &.icon-show {
      @if $icon-animations {
        animation: animate-error-icon 0.5s;

        .x-mark {
          animation: animate-error-x-mark 0.5s;
        }
      }
    }
  }

  &.warning {
    border-color: lighten($warning, 7);
    color: $warning;

    // Warning icon animation
    &.icon-show {
      @if $icon-animations {
        animation: animate-error-icon 0.5s;

        .icon-content {
          animation: animate-i-mark 0.5s;
        }
      }
    }
  }

  &.info {
    border-color: lighten($info, 20);
    color: $info;

    // Info icon animation
    &.icon-show {
      @if $icon-animations {
        animation: animate-error-icon 0.5s;

        .icon-content {
          animation: animate-i-mark 0.8s;
        }
      }
    }
  }

  &.question {
    border-color: lighten($question, 20);
    color: $question;

    // Question icon animation
    &.icon-show {
      @if $icon-animations {
        animation: animate-error-icon 0.5s;

        .icon-content {
          animation: animate-question-mark 0.8s;
        }
      }
    }
  }

  &.success {
    border-color: $success;
    color: $success;

    [class^='success-circular-line'] {
      // Emulate moving circular line
      position: absolute;
      width: 3.75em;
      height: 7.5em;
      transform: rotate(45deg);
      border-radius: 50%;

      &[class$='left'] {
        top: -0.4375em;
        left: -2.0635em;
        transform: rotate(-45deg);
        transform-origin: 3.75em 3.75em;
        border-radius: 7.5em 0 0 7.5em;

        @if $icon-zoom != 1 {
          zoom: $icon-zoom;
        }
      }

      &[class$='right'] {
        top: -0.6875em;
        left: 1.875em;
        transform: rotate(-45deg);
        transform-origin: 0 3.75em;
        border-radius: 0 7.5em 7.5em 0;

        @if $icon-zoom != 1 {
          zoom: $icon-zoom;
        }
      }
    }

    .success-ring {
      // Ring
      position: absolute;
      z-index: 2;
      top: -0.25em;
      left: -0.25em;
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      border: 0.25em solid $success-border;
      border-radius: 50%;

      @if $icon-zoom != 1 {
        zoom: $icon-zoom;
      }
    }

    .success-fix {
      // Hide corners left from animation
      position: absolute;
      z-index: 1;
      top: 0.5em;
      left: 1.625em;
      width: 0.4375em;
      height: 5.625em;
      transform: rotate(-45deg);

      @if $icon-zoom != 1 {
        zoom: $icon-zoom;
      }
    }

    [class^='success-line'] {
      display: block;
      position: absolute;
      z-index: 2;
      height: 0.3125em;
      border-radius: 0.125em;
      background-color: $success;

      &[class$='tip'] {
        top: 2.875em;
        left: 0.8125em;
        width: 1.5625em;
        transform: rotate(45deg);
      }

      &[class$='long'] {
        top: 2.375em;
        right: 0.5em;
        width: 2.9375em;
        transform: rotate(-45deg);
      }

      @if $icon-zoom != 1 {
        zoom: $icon-zoom;
      }
    }

    // Success icon animation
    &.icon-show {
      @if $icon-animations {
        .success-line-tip {
          animation: animate-success-line-tip 0.75s;
        }

        .success-line-long {
          animation: animate-success-line-long 0.75s;
        }

        .success-circular-line-right {
          animation: rotate-success-circular-line 4.25s ease-in;
        }
      }
    }
  }
}
