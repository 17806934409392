html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html {
  transition: all 0.3s;
  scroll-behavior: smooth;
  font-size: 12px;
  //font-family: "Baloo 2";
  font-family: 'nunito';

  ::-webkit-scrollbar {
    width: 9px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
  }
}

.nav-item {
  font-family: 'Baloo 2';
  font-size: 1.25em;
  font-weight: normal;
  letter-spacing: -0.2px;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;

  &:hover {
    color: $squash;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  font-family: 'Baloo 2';
  font-size: 4.0625em;
  font-weight: 600;
  line-height: 1.23;
  letter-spacing: -1.92px;
  color: white;
}

h2 {
  font-family: 'Baloo 2';
  font-size: 1.875em;
  font-weight: 500;
  letter-spacing: -0.77px;
  color: white;

  @include device-rule(mobile) {
    letter-spacing: -1.4px;
    line-height: 1.33;
  }

  span {
    color: $squash;
  }
}

h3 {
  font-family: 'Baloo 2';
  font-size: 1.875em;
  font-weight: 600;
  letter-spacing: -0.96px;
  color: $black;

  @include device-rule(mobile) {
    letter-spacing: -1.24px;
    line-height: 1.33;
    letter-spacing: -0.77px;
  }
}

h4 {
  font-family: 'Open Sans';
  font-size: 1em;
  font-weight: bold;
  color: $black;
}

p {
  font-family: 'Open Sans';
  font-size: 1em;
  color: $black;
}

// button,
// .button {
//   font-family: "Baloo 2";
//   font-size: 1.25em;
//   background-color: #1179a0;
//   box-shadow: 2px 3px 4px 1px rgba(43, 43, 43, 0.1);
//   padding: 4px 12px;
//   border-radius: 8px;
//   text-shadow: 0 2px 4px rgba(43, 43, 43, 0.5);
//   color: white;
//   border: none;
//   cursor: pointer;
//   transition: all 0.3s;
//   outline: none;
//   white-space: nowrap;
//   user-select: none;
//   min-width: 250px;
//   text-align: center;
//   text-decoration: none;

//   &:hover {
//     transform: scale(1.01);
//   }

//   &:active {
//     transform: scale(1);
//   }

//   &:disabled {
//     background-color: lightgray;
//     cursor: not-allowed;
//   }
// }

.content {
  display: flex;
  align-items: center;
  flex-direction: column;

  &.form {
    padding-top: 0;
  }
}

section {
  width: 1024px;

  @include device-rule(mobile) {
    width: 100%;
  }
}

// Custom Grid System - Bootstrap grid Like

.extra-col {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.col-0-5 {
  @extend .extra-col;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}

.col-0-75 {
  @extend .extra-col;
  flex: 0 0 6.250000005%;
  max-width: 6.250000005%;
}

.col-1-25 {
  @extend .extra-col;
  flex: 0 0 10.416666665%;
  max-width: 10.416666665%;
}

.col-1-5 {
  @extend .extra-col;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-1-75 {
  @extend .extra-col;
  flex: 0 0 14.583333335%;
  max-width: 14.583333335%;
}

.col-2-5 {
  @extend .extra-col;
  flex: 0 0 20.833333325%;
  max-width: 20.833333325%;
}

.col-3-5 {
  @extend .extra-col;
  flex: 0 0 29.166666655%;
  max-width: 29.166666655%;
}

.col-4-5 {
  @extend .extra-col;
  flex: 0 0 37.499999985%;
  max-width: 37.499999985%;
}

.col-5-5 {
  @extend .extra-col;
  flex: 0 0 45.833333315%;
  max-width: 45.833333315%;
}

.col-6-5 {
  @extend .extra-col;
  flex: 0 0 54.166666645%;
  max-width: 54.166666645%;
}

.col-7-5 {
  @extend .extra-col;
  flex: 0 0 62.499999975%;
  max-width: 62.499999975%;
}

.col-8-5 {
  @extend .extra-col;
  flex: 0 0 70.833333305%;
  max-width: 70.833333305%;
}

.col-9-5 {
  @extend .extra-col;
  flex: 0 0 79.166666635%;
  max-width: 79.166666635%;
}

.col-10-5 {
  @extend .extra-col;
  flex: 0 0 87.499999965%;
  max-width: 87.499999965%;
}

.col-11-5 {
  @extend .extra-col;
  flex: 0 0 95.8333333%;
  max-width: 95.8333333%;
}
