$device-enabled: true !default;
$device-mobile-threshold: 1024px !default;
$device-tablet-threshold: 1500px !default; // includes portrait tablet (900px) and landscape tablet (1200px)
$device-medium-threshold: 1500px !default;

$device-list: (mobile, tablet, desktop, medium-desktop) !default;

$device-rules: (
  screen: "screen",
  print: "print",
  handheld: "handheld",
  mobile: "(max-width: #{$device-mobile-threshold})",
  tablet:
    "(min-width: #{$device-mobile-threshold+1}) and (max-width: #{$device-tablet-threshold})",
  medium-desktop:
    "(min-width: #{$device-tablet-threshold}) and (max-width: #{$device-medium-threshold})",
  desktop: "(min-width: #{$device-tablet-threshold+1})",
  landscape: "(orientation: landscape)",
  portrait: "(orientation: portrait)",
) !default;

// apply device-related media query
@mixin device-rule($rules...) {
  $query: "";

  @each $rule in $rules {
    $op: "";

    @if ($query != "") {
      $op: " and ";
    }

    $query: $query + $op + map_get($device-rules, $rule);
  }

  @media #{$query} {
    @content;
  }
}
